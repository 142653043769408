import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
    createGroupAPI,
    getGroupListAPI,
    updateGroupAPI,
    updateGroupAddressAPI,
    createGroupContactAPI,
    getGroupContactsAPI,
    updateGroupContactAPI,
    inviteGroupContactAPI,
    deleteLocationContactAPI,
    createLocationContactAPI,
    getLocationContactsAPI,
    updateLocationContactAPI,
    getGroupFamilyAPI,
    onboardGroupAPI,
    getGroupAccountData,
    getGroupBillingDetails,
    getGroupReportSettingsAPI,
    updateGroupReportSettingsAPI,
    getGroupOnboardingStatusAPI,
    updateGroupOnboardingStatusAPI,
    updateConectionStatus
} from 'APIs/group';

import appLogger from 'utils/common/appLogger.ts';
import ResponseStatus from 'models/common/ResponseStatus.tsx';

// ==============================|| REDUX STORE: GROUP DATA ||============================== //

const NAME = 'groupSlice()';

const initialState = {
    initialized: false,
    groups: [],
    groupFamily: [],
    noGroupsFound:  false,
    groupContacts: [],
    locationContacts: [],
    groupAccountData: [],
    groupRow: null,
    locationRow: null,
    groupBillingData: [],
    groupReportSettings: [],
    groupOnboardingStatus: [],
    connectionStatus: false,
    error: null
};

/**
 * Create a new group
 */
const CREATE_GROUP = createAsyncThunk('group/create', async ({ data }) => {
    try {
        const response = await createGroupAPI({ data });
        appLogger.debug(NAME, 'CREATE_GROUP_SUCCESS', response);
        return response;
    } catch (error) {
        // appLogger.error(NAME, 'CREATE_GROUP', error);
        const responseStatus = new ResponseStatus();
        responseStatus.status = error.response.status;
        responseStatus.message = error.response.message;
        responseStatus.statusText = error.response.statusText;
        responseStatus.data = error.response.data;
        return responseStatus;
    }
});

/**
 * Onboard a group
 */
const ONBOARD_GROUP = createAsyncThunk('group/onboard', async ({ groupId, data }) => {
    try {
        const response = await onboardGroupAPI({ groupId, data });
        appLogger.debug(NAME, 'ONBOARD_GROUP_SUCCESS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'ONBOARD_GROUP', error);
        return null;
    }
});

/**
 * Get the family of a group
 */
const GET_GROUP_FAMILY = createAsyncThunk('group/family', async ({ id }) => {
    try {
        const response = await getGroupFamilyAPI({ groupId: id });
        appLogger.debug(NAME, 'GET_GROUP_FAMILY_SUCCESS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'GET_GROUP_FAMILY_ERROR', error);
        const responseStatus = new ResponseStatus();
        responseStatus.status = error.response.status;
        responseStatus.message = error.response.message;
        responseStatus.statusText = error.response.statusText;
        responseStatus.data = error.response.data;
        return responseStatus;
    }
});

/**
 * Get the list of groups
 */
const GET_GROUPS_LIST = createAsyncThunk('groups/list', async () => {
    try {
        const response = await getGroupListAPI();
        appLogger.debug(NAME, 'GET_GROUPS_LIST_SUCCESS', response);
        return response;
    } catch (error) {
        const responseStatus = new ResponseStatus();
        responseStatus.status = error.response.status;
        responseStatus.message = error.response.message;
        responseStatus.statusText = error.response.statusText;
        responseStatus.data = error.response.data;
        return responseStatus;
    }
});

const UPDATE_GROUP = createAsyncThunk('groups/update', async ({ id, data = {} }) => {
    appLogger.debug(NAME, 'UPDATE_GROUP (id, data)', id, data);
    if (!id) {
        throw new Error('Missing groupID');
    }
    try {
        const response = await updateGroupAPI({ id, data });
        appLogger.debug(NAME, 'UPDATE_GROUP_SUCCESS', response);
        return response;
    } catch (error) {
        const responseStatus = new ResponseStatus();
        responseStatus.status = error.response.status;
        responseStatus.message = error.response.message;
        responseStatus.statusText = error.response.statusText;
        responseStatus.data = error.response.data;
        return responseStatus;
    }
});

const UPDATE_GROUP_ADDRESS = createAsyncThunk('groups/update/Address', async ({ id, data = {} }) => {
    if (!id) {
        throw new Error('Missing groupID');
    }
    try {
        const response = await updateGroupAddressAPI({ id, data });
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_GROUP_ADDRESS', error);
        return null;
    }
});

// updateConnection

const UPDATE_CONNECTION_PROCESS = createAsyncThunk('update/connection', async (data) => {
    const response = await updateConectionStatus(data);
    return response;
});

/**
 * Create a new group contact
 */
const CREATE_GROUP_CONTACT = createAsyncThunk('groupContact/create', async ({ groupId, data }) => {
    const response = await createGroupContactAPI(groupId, data);
    return response;
});

/**
 * Update a group contact
 */
const UPDATE_GROUP_CONTACT = createAsyncThunk('groupContact/update', async ({ groupId, id, data }) => {
    const response = await updateGroupContactAPI(groupId, id, data);
    return response;
});

/**
 * Update a group contact
 */
const INVITE_GROUP_CONTACT = createAsyncThunk('groupContact/invite', async ({ groupId, id }) => {
    const response = await inviteGroupContactAPI(groupId, id);
    return response;
});

/**
 * Delete group contact
 */
const DELETE_LOCATION_CONTACT = createAsyncThunk('groupContact/delete', async ({ groupId, id }) => {
    try {
        const response = await deleteLocationContactAPI(groupId, id);
        return response;
    } catch (error) {
        const responseStatus = new ResponseStatus();
        responseStatus.status = error.response.status;
        responseStatus.message = error.response.message;
        responseStatus.statusText = error.response.statusText;
        responseStatus.data = error.response.data;
        return responseStatus;
    }
});

/**
 * Get the list of group contacts
 */
const GET_GROUP_CONTACTS = createAsyncThunk('groupContact/list', async ({groupId, params=null}) => {
    const response = await getGroupContactsAPI(groupId, params);
    console.log('GET_GROUP_CONTACTS', response);
    return response;
});

/**
 * Create a new location contact
 */
const CREATE_LOCATION_CONTACT = createAsyncThunk('locationContact/create', async ({ groupId, data }) => {
    const response = await createLocationContactAPI(groupId, data);
    return response;
});

/**
 * Update a location contact
 */
const UPDATE_LOCATION_CONTACT = createAsyncThunk('locationContact/update', async ({ groupId, id, data }) => {
    appLogger.debug(NAME, 'UPDATE_LOCATION_CONTACT', groupId, id, data);
    const response = await updateLocationContactAPI(groupId, id, data);
    return response;
});

/**
 * Get the list of location contacts
 */
const GET_LOCATION_CONTACTS = createAsyncThunk('locationContact/list', async (groupId) => {
    const response = await getLocationContactsAPI(groupId);
    return response;
});

const getGroupById = (state, id) => state.group?.groups?.find((group) => group.id === id);

/**
 * Get the locations of a group
 */
const GET_GROUP_LOCATIONS = createAsyncThunk('groups/get', async ({ id }) => {
    // TODO: Check if id exists in state.groups
    // If so, return it
    // If not, fetch it from the API and save to state
    try {
        const response = getGroupById(id);
        appLogger.debug(NAME, 'GET_GROUP_LOCATIONS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'GET_GROUP_LOCATIONS', error);
        return null;
    }
});

const GET_GROUP_ACCOUNT_DATA = createAsyncThunk(`groups/accounts`, async (groupId) => {
    const response = await getGroupAccountData(groupId);
    return response;
});

/**
 * Get the group billing details
 */
const GET_GROUPS_BILLING_DETAILS = createAsyncThunk('groups/billing', async (groupId) => {
    try {
        const response = await getGroupBillingDetails(groupId);
        appLogger.debug(NAME, 'GET_GROUPS_BILLING_DETAILS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'GET_GROUPS_BILLING_DETAILS', error);
        return null;
    }
});

/**  
GRoup Agreement details
*/


/**
 * Get the group billing details
 */
const GET_GROUPS_REPORT_SETTINGS = createAsyncThunk('groups/report-settings', async (groupId) => {
    try {
        const response = await getGroupReportSettingsAPI(groupId);
        appLogger.debug(NAME, 'GET_GROUPS_REPORT_SETTINGS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'GET_GROUPS_REPORT_SETTINGS', error);
        return null;
    }
});


/**
 * Update a group's report settings
 */
const UPDATE_GROUPS_REPORT_SETTINGS = createAsyncThunk('group/report-settings/update', async ({ groupId, data }) => {
    appLogger.debug(NAME, 'UPDATE_REPORT_SETTINGS', groupId, data);
    const response = await updateGroupReportSettingsAPI({ id: groupId, data });
    return response;
});

/**
 * Get the group onboarding status
 */
const GET_GROUPS_ONBOARDING_STATUS = createAsyncThunk('groups/onboarding-status', async (groupId) => {
    try {
        const response = await getGroupOnboardingStatusAPI(groupId);
        appLogger.debug(NAME, 'GET_GROUPS_ONBOARDING_STATUS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'GET_GROUPS_ONBOARDING_STATUS', error);
        return null;
    }
});

/**
 * Update a group onboarding status
 */
const UPDATE_GROUP_ONBOARDING_STATUS = createAsyncThunk('group/onboarding-status/update', async ({ groupId, data }) => {
    appLogger.debug(NAME, 'UPDATE_GROUP_ONBOARDING_STATUS', groupId, data);
    const response = await updateGroupOnboardingStatusAPI({ id: groupId, data });
    return response;
});

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        create(state, action) {
            state.groups.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.groups = [];
            state.groupContacts = [];
            state.locationContacts = [];
            state.groupRow = null;
            state.locationRow = null;
            state.groupFamily = [];
            state.noGroupsFound = false;
            state.groupAccountData = [];
            state.groupBillingData = [];
            state.groupAgreementData = [];
            state.groupReportSettings = [];
            state.groupOnboardingStatus = [];
            state.connectionStatus = false;
        },
        resetNotFound(state) {
            state.noGroupsFound = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_GROUP.fulfilled, (state, action) => {
                appLogger.debug('CREATE_GROUP.fulfilled', action.payload);
                state.groups.push(action.payload);
                const g = state.groups[state.groups.length - 1];
                if (g !== null) {
                    // Newly created groups shall not have any contacts
                    state.groupContacts = [];
                    state.locationContacts = [];
                    if (g.primary_location === true) {
                        state.groupRow = g;
                        state.locationRow = null;
                    } else {
                        state.groupRow = null;
                        state.locationRow = g;
                    }
                } else {
                    appLogger.error(NAME, 'CREATE_GROUP.fulfilled: group is null');
                    state.groupRow = null;
                    state.locationRow = null;
                }
            })
            .addCase(GET_GROUPS_LIST.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'CREATE_GROUP_LIST.fulfilled', action);
                state.groups = [...action.payload];
                state.initialized = true;
                // Reset the contacts on reading the list of groups
                state.groupContacts = [];
                state.locationContacts = [];
            })
            .addCase(GET_GROUP_FAMILY.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'GET_GROUP_FAMILY.fulfilled', action);
                /**
                 * If the group family is not null, then the group exists
                 * and the group family is the list of groups in the family
                 * otherwise the group does not exist or user is not authorized
                 * to view the group; setthe noGroupsFound flag to true
                 */
                if (action.payload !== null) {
                    state.groupFamily = [...action.payload];
                    state.noGroupsFound = false;
                } else {
                    state.groupFamily = [];
                    state.noGroupsFound = true;
                }
                // Reset the contacts on reading the list of groups
                state.groupContacts = [];
                state.locationContacts = [];
            })
            .addCase(UPDATE_GROUP.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'UPDATE_GROUP.fulfilled', action);
                const g = action.payload;
                if (g !== null) {
                    if (g.primary_location === true) {
                        state.groupRow = g;
                        state.locationRow = null;
                    } else {
                        state.groupRow = null;
                        state.locationRow = g;
                    }
                } else {
                    appLogger.error(NAME, 'UPDATE_GROUP.fulfilled: group is null');
                    state.groupRow = null;
                    state.locationRow = null;
                }
                state.groups = state.groups.map((group) => {
                    if (group?.id === action.payload.id) {
                        return { ...group, ...action.payload };
                    }
                    return group;
                });
            })
            .addCase(UPDATE_GROUP_ADDRESS.fulfilled, (state, action) => {
                appLogger.debug('UPDATE_GROUP_ADDRESS.fulfilled', action.payload);
            })
            .addCase(CREATE_GROUP_CONTACT.fulfilled, (state, action) => {
                appLogger.debug('CREATE_GROUP_CONTACT.fulfilled', action.payload);
                state.groupContacts.push(action.payload);
            })
            .addCase(UPDATE_GROUP_CONTACT.fulfilled, (state, action) => {
                appLogger.debug('UPDATE_GROUP_CONTACT.fulfilled.action', action);
                state.groupContacts = action.payload;
            })
            .addCase(INVITE_GROUP_CONTACT.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'INVITE_GROUP_CONTACT.fulfilled', action.payload);
            })
            .addCase(DELETE_LOCATION_CONTACT.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'DELETE_LOCATION_CONTACT.fulfilled', action);
            })
            .addCase(GET_GROUP_CONTACTS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'GET_GROUP_CONTACTS.fulfilled', action.payload);
                state.groupContacts = action.payload;
            })
            .addCase(CREATE_LOCATION_CONTACT.fulfilled, (state, action) => {
                appLogger.debug('CREATE_LOCATION_CONTACT.fulfilled', action.payload);
                state.locationContacts.push(action.payload);
            })
            .addCase(UPDATE_LOCATION_CONTACT.fulfilled, (state, action) => {
                appLogger.debug('UPDATE_LOCATION_CONTACT.fulfilled.action', action);
                appLogger.debug('UPDATE_LOCATION_CONTACT.fulfilled.state', state);
                state.locationContacts = state.locationContacts.map((contact) => {
                    if (contact?.id === action.payload.id) {
                        return { ...contact, ...action.payload };
                    }
                    return contact;
                });
            })
            .addCase(GET_LOCATION_CONTACTS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'GET_LOCATION_CONTACTS.fulfilled', action);
                state.groupClocationContactsontacts = [...action.payload];
            })
            .addCase(ONBOARD_GROUP.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'ONBOARD_GROUP.fulfilled', action);
                state.groups = [...action.payload];
            })
            .addCase(GET_GROUP_ACCOUNT_DATA.fulfilled, (state, action) => {
                state.groupAccountData = [action.payload];
            })
            .addCase(GET_GROUPS_BILLING_DETAILS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'GET_GROUPS_BILLING_DETAILS.fulfilled', action);
                state.groupBillingData = action.payload;
            })
            .addCase(GET_GROUPS_REPORT_SETTINGS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'GET_GROUPS_REPORT_SETTINGS.fulfilled', action);
                state.groupReportSettings = action.payload;
            })
            .addCase(UPDATE_GROUPS_REPORT_SETTINGS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'UPDATE_GROUPS_REPORT_SETTINGS.fulfilled', action);
                state.groupReportSettings = state.groupReportSettings.map((groupReportSettings) => {
                    if (groupReportSettings?.id === action.payload.id) {
                        return { ...groupReportSettings, ...action.payload };
                    }
                    return groupReportSettings;
                });
            })
            .addCase(GET_GROUPS_ONBOARDING_STATUS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'GET_GROUPS_ONBOARDING_STATUS.fulfilled', action);
                state.groupOnboardingStatus = action.payload;
            })
            .addCase(UPDATE_GROUP_ONBOARDING_STATUS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'UPDATE_GROUP_ONBOARDING_STATUS.fulfilled', action);
                state.groupOnboardingStatus = action.payload;
            })
            .addCase(UPDATE_CONNECTION_PROCESS.fulfilled, (state, action) => {
                appLogger.debug(NAME, 'UPDATE_CONNECTION_PROCESS.fulfilled', action);
                state.connectionStatus = true;
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                    state.groupRow = null;
                    state.locationRow = null;
                }
            );
    }
});

const { create, logout } = groupSlice.actions;
const initialized = (state) => state?.group?.initialized;
const groups = (state) => state?.group?.groups;
const groupRow = (state) => state?.group?.groupRow;
const locationRow = (state) => state?.group?.locationRow;
const groupFamily = (state) => state.group?.groupFamily;
const noGroupsFound = (state) => state.group?.noGroupsFound;
const groupContacts = (state) => state.group?.groupContacts;
const locationContacts = (state) => state.group?.locationContacts;
const groupAccountData = (state) => state.group?.groupAccountData;
const groupBillingData = (state) => state.group?.groupBillingData;
const groupAgreementData = (state) => state.group?.groupAgreementData;
const groupReportSettings = (state) => state.group?.groupReportSettings;
const groupOnboardingStatus = (state) => state.group?.groupOnboardingStatus;
const userConnnectionStatus = (state) => state.group?.connectionStatus;

export {
    initialized,
    create,
    logout,
    CREATE_GROUP,
    GET_GROUPS_LIST,
    UPDATE_GROUP,
    UPDATE_GROUP_ADDRESS,
    CREATE_GROUP_CONTACT,
    UPDATE_GROUP_CONTACT,
    INVITE_GROUP_CONTACT,
    DELETE_LOCATION_CONTACT,
    GET_GROUP_CONTACTS,
    CREATE_LOCATION_CONTACT,
    UPDATE_LOCATION_CONTACT,
    GET_LOCATION_CONTACTS,
    GET_GROUP_FAMILY,
    ONBOARD_GROUP,
    GET_GROUP_ACCOUNT_DATA,
    GET_GROUPS_BILLING_DETAILS,
    GET_GROUPS_REPORT_SETTINGS,
    UPDATE_GROUPS_REPORT_SETTINGS,
    GET_GROUPS_ONBOARDING_STATUS,
    UPDATE_GROUP_ONBOARDING_STATUS,
    UPDATE_CONNECTION_PROCESS,
    groups,
    groupRow,
    groupFamily,
    noGroupsFound,
    locationRow,
    getGroupById,
    groupContacts,
    locationContacts,
    groupAccountData,
    groupBillingData,
    groupReportSettings,
    groupOnboardingStatus,
    userConnnectionStatus,
};

export const { resetNotFound} = groupSlice.actions;

export default groupSlice.reducer;
