import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { promisify } from 'util';

// project imports
import { AWS_API } from 'config';
import { LOGIN, LOGOUT, GET_USER_DATA } from 'store/slices/accountSlice';
import apiClient, { djangoApiClient } from 'APIs/apiClient';
import { logout as menuLogout } from 'store/slices/menu';
import { logout as usersLogout } from 'store/slices/userSlice';
import { logout as tasksLogout } from 'store/slices/taskSlice';
import { logout as messagesLogout } from 'store/slices/messageSlice';
import { logout as clientLogout } from 'store/slices/clientSlice';
import { logout as reportLogout } from 'store/slices/reportSlice';
import { logout as groupLogout, UPDATE_CONNECTION_PROCESS  } from 'store/slices/groupSlice';
import { logout as appsLogout } from 'store/slices/connectedAppSlice';
import { logout as userLogout } from 'store/slices/admin/userManagementSlice';
import { logout as reportAccess} from 'store/slices/reportAccessSlice';

import { USER_MESSAGE } from 'store/slices/statusSlice';
import appLogger from '../utils/common/appLogger.ts';
import { resetOnboardingState } from '../store/slices/onBoardingSlice';

const NAME = 'AWSCognitoContext(): ';

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

const getUserType = (groups = []) => {
    // debugger; // eslint-disable-line no-debugger
    if (groups.includes('admin')) {
        return 'admin';
    }
    if (groups.includes('am')) {
        return 'am';
    }
    return 'client';
};
const formatSession = (sessionData) => {
    // debugger; // eslint-disable-line no-debugger
    const userData = sessionData.idToken.payload;
    const user = {};
    if (userData['cognito:username']) user.userName = userData['cognito:username'];
    if (userData.email) user.email = userData.email;
    if (userData['custom:personID']) user.personID = userData['custom:personID'];
    if (userData['custom:orgID']) user.orgID = userData['custom:orgID'];
    if (userData.name) user.name = userData.name;
    if (userData.given_name) user.givenName = userData.given_name;
    if (userData.family_name) user.familyName = userData.family_name;
    user.type = getUserType(userData['cognito:groups']);

    const session = {
        credentials: {
            accessToken: sessionData.accessToken.jwtToken,
            idToken: sessionData.idToken.jwtToken,
            refreshToken: sessionData.refreshToken.token
        },
        user
    };
    return session;
};

const setAPIToken = (jwtToken) => {
    // Do not log the token values
    // appLogger.debug(NAME,'setAPIToken', jwtToken);
    if (jwtToken) {
        appLogger.debug(NAME, 'setAPIHeader - OK');
        apiClient.defaults.headers.common.Authorization = jwtToken;
    } else {
        appLogger.debug(NAME, 'setAPIHeader - REMOVE');
        delete apiClient.defaults.headers.common.Authorization;
    }
};

// ==============================|| AWS COGNITO CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state?.account?.isLoggedIn);
    const userData = useSelector((state) => state?.account?.user) || {};
    const { onboardComplete = true, type } = userData;
    let savedUserAttributes;
    let savedCognitoUser;

    const userType = type || 'client';

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            loggedInUser.signOut();
        }

        setAPIToken();
        dispatch(LOGOUT());
        dispatch(menuLogout());
        dispatch(usersLogout());
        dispatch(tasksLogout());
        dispatch(messagesLogout());
        dispatch(clientLogout());
        dispatch(reportLogout());
        dispatch(groupLogout());
        dispatch(appsLogout());
        dispatch(userLogout());
        dispatch(reportAccess());
        dispatch(resetOnboardingState());
    };

    // Gets a new Cognito session. Returns a promise.
    const getSession = () =>
        new Promise((resolve, reject) => {
            appLogger.debug(NAME, 'Get current user>>>');
            const cognitoUser = userPool.getCurrentUser();
            if (!cognitoUser) {
                reject(new Error(`No current user`));
                return;
            }
            appLogger.debug(NAME, 'cognitoUser.getSession>>>');
            try {
                cognitoUser.getSession((err, result) => {
                    if (err || !result) {
                        reject(new Error(`Failure getting Cognito session: ${err}`));
                    }
                    // resolve(formatSession(result));
                    appLogger.debug(NAME, 'Returning session>>>');
                    resolve(result);
                });
            } catch (error) {
                appLogger.error(NAME, error);
            }
        });

    const checkToken = async () => {
        appLogger.debug(NAME, 'Checking Token');
        const session = await getSession();
        const expiry = session.getIdToken().getExpiration();
        appLogger.debug(NAME, `Expiry: ${expiry}`);
        const now = Math.floor(new Date() / 1000);
        appLogger.debug(NAME, `Now: ${now}`);
        appLogger.debug(NAME, `Difference: ${expiry - now}`);
    };

    // Returns a valid JWT Token, the token will be refreshed automatically if needed
    const getToken = async () => {
        try {
            appLogger.debug(NAME, 'Getting Token>>>>');
            const session = await getSession();
            return session.getIdToken().getJwtToken();
        } catch (error) {
            // There was an issue => Logout
            appLogger.error(NAME, error.message);
            logout();
        }
        return undefined;
    };

    // Returns a valid JWT Token, the token will be refreshed automatically if needed
    const getAccessToken = async () => {
        try {
            appLogger.debug(NAME, 'Getting Token>>>>');
            const session = await getSession();
            // appLogger.debug(NAME, 'Returning Token>>>>', session.getAccessToken().getJwtToken());
            return session.getAccessToken().getJwtToken();
        } catch (error) {
            // There was an issue => Logout
            appLogger.debug(NAME, error.message);
            logout();
        }
        return undefined;
    };

    const axiosRequestInterceptor = () => {
        apiClient.interceptors.request.use(
            async (config) => {
                appLogger.debug(NAME, 'INTERCEPTOR >>>getting Token');
                const token = await getToken();
                config.headers.Authorization = token;
                appLogger.debug(NAME, 'INTERCEPTOR >>>updating Token');
                return config;
            },
            (error) => {
                // Error
                appLogger.error(NAME, error);
                return Promise.reject(error);
            }
        );
    };

    const axiosDjangoRequestInterceptor = () => {
        djangoApiClient.interceptors.request.use(
            async (config) => {
                appLogger.debug(NAME, 'INTERCEPTOR >>>getting Token');
                const token = await getAccessToken();
                config.headers.Authorization = `Bearer ${token}`;
                appLogger.debug(NAME, 'INTERCEPTOR >>>updating Token');
                return config;
            },
            (error) => {
                // Error
                appLogger.error(NAME, error);
                return Promise.reject(error);
            }
        );
    };

    const axiosDjangoResponseInterceptor = () => {
        djangoApiClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response?.status === 401) {
                    appLogger.error(NAME, 'INTERCEPTOR >>>401', error.response);
                    // The response from the API will contain the noRetry key if we should not retry this same request
                    if (error.response?.data?.noRetry) {
                        // Return the response and let the apiClient determine how to handle it
                        return error.response;
                    }
                    if (!originalRequest.retry) {
                        originalRequest.retry = true;
                        // Will need to do a refresh
                        const newToken = await getToken();
                        originalRequest.headers.Authorization = newToken;
                        setAPIToken(newToken);
                        return djangoApiClient(originalRequest);
                    }
                    // Tried before - no luck - goodbye!
                    logout();
                    return null;
                }
                if (error.response?.status < 500) {
                    const defaultErrorMessage = 'There was a problem with this request';
                    const errorMessage = error.response?.data?.errorMessage || error.response?.data?.error || defaultErrorMessage;
                    dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
                    throw error;
                } else {
                    // TODO - other non 200 errors
                    appLogger.error(NAME, '>>>return error: ', error.response?.data?.errorMessage, '>>>status: ', error.response?.status);
                    const defaultErrorMessage = 'Sorry, something went wrong';
                    const errorMessage = error.response?.data?.errorMessage || error.response?.data?.error || defaultErrorMessage;
                    dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
                    throw error;
                }
            }
        );
    };

    const axiosResponseInterceptor = () => {
        apiClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response.status === 401) {
                    appLogger.error(NAME, 'INTERCEPTOR >>>401');
                    if (!originalRequest.retry) {
                        originalRequest.retry = true;
                        // Will need to do a refresh
                        const newToken = await getToken();
                        originalRequest.headers.Authorization = newToken;
                        setAPIToken(newToken);
                        return apiClient(originalRequest);
                    }
                    // Tried before - no luck - goodbye!
                    logout();
                    return null;
                }
                // TODO - other non 200 errors
                appLogger.error(NAME, '>>>return error: ', error.response?.data?.errorMessage);
                appLogger.error(NAME, '>>>status: ', error.response?.status);
                const errorMessage = error.response?.data?.errorMessage;
                // const errorMessage = `There was a problem with this request: ${error.response?.data?.errorMessage}`;
                dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
                throw error;
            }
        );
    };

    const login = (email, password) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        const authData = new AuthenticationDetails({
            Username: email,
            Password: password
        });
        return new Promise((resolve, reject) => {
            appLogger.debug(NAME, 'authenticating user');
            usr.authenticateUser(authData, {
                onSuccess: (session) => {
                    // Login with user data
                    const { user } = formatSession(session) || {};
                    dispatch(LOGIN(user));
                    dispatch(GET_USER_DATA({ email: user?.email, userId: user?.userName }));
                    dispatch(UPDATE_CONNECTION_PROCESS({email: user?.email}))
                    // Update API Token
                    setAPIToken(session.getIdToken().getJwtToken());
                },
                onFailure: reject,
                newPasswordRequired: (userAttributes) => {
                    appLogger.debug(NAME, 'here>>>');
                    appLogger.debug(NAME, userAttributes);
                    delete userAttributes.email_verified;
                    delete userAttributes.email;
                    savedUserAttributes = userAttributes;
                    savedCognitoUser = usr;
                    // setSavedUserAttributes(userAttributes);
                    const responseError = new Error('A new password is required');
                    responseError.type = 'newPasswordRequired';
                    throw responseError;
                }
            });
        });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const token = await getToken();
                setAPIToken(token);
                axiosResponseInterceptor();
                axiosRequestInterceptor();
                axiosDjangoRequestInterceptor();
                axiosDjangoResponseInterceptor();
                const loggedInUser = userPool.getCurrentUser();
                if (loggedInUser) {
                    const session = await getSession();
                    // Login with user data
                    const { user } = formatSession(session) || {};
                    dispatch(LOGIN(user));
                    dispatch(GET_USER_DATA({ email: user?.email, userId: user?.userName }));
                } else {
                    logout();
                }
            } catch (err) {
                appLogger.error(NAME, err);
                logout();
            }
        };
        init();
    }, [dispatch]);

    const register = (email, password, firstName, lastName) => {
        const emailAttribute = new CognitoUserAttribute({
            Name: 'email',
            Value: email
        });
        const nameAttribute = new CognitoUserAttribute({
            Name: 'name',
            Value: `${firstName} ${lastName}`
        });
        const givenNameAttribute = new CognitoUserAttribute({
            Name: 'given_name',
            Value: `${firstName}`
        });
        const familyNameAttribute = new CognitoUserAttribute({
            Name: 'family_name',
            Value: `${lastName}`
        });
        const attributes = [emailAttribute, nameAttribute, givenNameAttribute, familyNameAttribute];
        const promisifiedSignUp = promisify(userPool.signUp).bind(userPool);

        return promisifiedSignUp(email, password, attributes, null);
    };

    const confirmRegistration = (email, code) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        const promisifiedVerify = promisify(usr.confirmRegistration).bind(usr);
        return promisifiedVerify(code, true);
    };

    const resendCode = (email) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        const promisifiedResend = promisify(usr.resendConfirmationCode).bind(usr);
        return promisifiedResend();
    };

    const forgotPassword = (email) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise((resolve, reject) => {
            usr.forgotPassword({
                onSuccess: resolve,
                onFailure: reject
            });
        });
    };

    const resetPassword = (email, code, newPassword) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise((resolve, reject) => {
            usr.confirmPassword(code, newPassword, {
                onSuccess: resolve,
                onFailure: reject
            });
        });
    };

    const newPassword = async (newPassword) => {
        appLogger.debug(NAME, 'here');
        return new Promise((resolve, reject) => {
            appLogger.debug(NAME, savedCognitoUser);
            appLogger.debug(NAME, savedUserAttributes);
            savedCognitoUser.completeNewPasswordChallenge(newPassword, savedUserAttributes, {
                onSuccess: resolve,
                onFailure: reject
            });
        });
    };

    // Check to see if a forced password change is in progress
    const newPasswordInProgress = () => {
        // debugger; // eslint-disable-line no-debugger
        const inProgress = !!savedCognitoUser && !!savedUserAttributes;
        return inProgress;
    };

    return (
        <AWSCognitoContext.Provider
            value={{
                isLoggedIn,
                userType,
                onboardComplete,
                login,
                logout,
                register,
                confirmRegistration,
                resendCode,
                forgotPassword,
                resetPassword,
                getSession,
                getToken,
                checkToken,
                newPassword,
                newPasswordInProgress
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

AWSCognitoProvider.propTypes = {
    children: PropTypes.node
};

export default AWSCognitoContext;
