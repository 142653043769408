import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';
import { MULTY_ACCOUNT_ENABLED_FLAG, CLIENT_ACCOUNT_ENABLED_FLAG, WORK_ENABLED_FLAG } from '../config';

// page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const SamplePage2 = Loadable(lazy(() => import('views/sample-page2')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const AdminDashboard = Loadable(lazy(() => import('views/dashboard/AdminDashboard')));
const UsersPage = Loadable(lazy(() => import('views/users')));
const UserDetail = Loadable(lazy(() => import('views/users/UserDetail')));
// const ClientListPage = Loadable(lazy(() => import('views/clients/ClientList')));
const ClientsPage = Loadable(lazy(() => import('views/clients')));
const ClientDetail = Loadable(lazy(() => import('views/clients/ClientDetail')));
const ComingSoon = Loadable(lazy(() => import('views/dashboard/ComingSoon')));
const NotFoundPage = Loadable(lazy(() => import('views/common/404')));
const AccountsPage = Loadable(lazy(() => import('views/accounts/Accounts')))
const GroupDetails = Loadable(lazy(() => import('views/group/GroupDetails')));
const AdministrationPage = Loadable(lazy(() => import('views/administration')));
const UserManagementPage = Loadable(lazy(() => import('views/administration/usermanagement/UserManagement')));
const PlanAdminPage = Loadable(lazy(() => import('views/administration/planadministration/PlanAdminPage')));
const ReportsPage = Loadable(lazy(() => import('views/reporting')));
const MessageAdminPage = Loadable(lazy(() => import('views/administration/messageadministration/MessageAdministration')));
const EmployeeManagementPage = Loadable(lazy(() => import('views/administration/employeemanagement/EmployeeManagement')));
const EmployeeDetailsPage = Loadable(lazy(() => import('views/administration/employeemanagement/EmployeeDetails')));
const IntegrationManagementPage = Loadable(lazy(() => import('views/administration/integrationmanagement/IntegrationManagement')));
const ManageTemplatesPage =Loadable(lazy(()=>import('work/template/ManageTemplates')))
const PMReportsPage =Loadable(lazy(()=>import('work/pm-reports/PMReports')))
const ModifyTemplatesPage =Loadable(lazy(()=>import('work/template/ModifyTemplate')))
const OperationsDashboard = Loadable(lazy(() => import('views/dashboard/OperationsDashboard')));
const DownloadPage = Loadable(lazy(() => import('views/administration/downloads/DownloadEntities')));
const PracticeWorkPage = Loadable(lazy(() => import('work/practice/index')));
const OnboardingPending = Loadable(lazy(() => import('views/dashboard/AdminCards/OnboardingPending')));
const ReportsPending = Loadable(lazy(() => import('views/dashboard/AdminCards/ReportsPending')));
const EventDetails = Loadable(lazy(() => import('views/dashboard/AdminCards/EventDetails')));


// const DownloadPage = Loadable(lazy(() => import('views/administration/downloads/DownloadEntities')));


// ==============================|| MAIN ROUTING ||============================== //

const multyFlag = MULTY_ACCOUNT_ENABLED_FLAG === 'true';
const clientFlag = CLIENT_ACCOUNT_ENABLED_FLAG === 'true';
const workFlag = WORK_ENABLED_FLAG === 'true';

const MainRoutes = {
    path: '/',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: '/',
            element: <AdminDashboard />
        },
        {
            path: '/page1',
            element: <SamplePage />
        },
        {
            path: '/page2',
            element: <SamplePage2 />
        },
        {
            path: '/admin/users',
            element: <UsersPage />
        },
        {
            path: '/admin/user',
            element: <UserDetail />,
            children: [{ path: ':id', element: <UserDetail /> }]
        },
        clientFlag ?
        {
            path: '/admin/clients',
            element: <ClientsPage />
        }: '',
        {
            path: '/admin/downloads',
            element: <DownloadPage />
        },
        {
            path: 'admin/settings',
            element: <UserSettings />
        },
        {
            path: '/admin/messages',
            element: <ComingSoon props={{ pageName: 'Admin Messages Page' }} />
        },
        {
            path: '/admin/reports',
            element: <ReportsPage />
        },
        {
            path: '/admin/payroll',
            element: <ComingSoon props={{ pageName: 'Admin Payroll Page' }} />
        },
        {
            path: '/admin/administration',
            element: <AdministrationPage props={{ pageName: 'Administration Page' }} />
        },
        {
            path: '/admin/usermanagement',
            element: <UserManagementPage props={{ pageName: 'User management page' }} />
        },
        {
            path: '/admin/onboarding-pending',
            element: <OnboardingPending props={{ pageName: 'Onboarding pending page' }} />
        },
        {
            path: '/admin/event-details',
            element: <EventDetails props={{ pageName: 'event details page' }} />
        },
        {
            path: '/admin/reports-pending',
            element: <ReportsPending props={{ pageName: 'Reports pending page' }} />
        },
        {
            path: '/admin/planaadministration',
            element: <PlanAdminPage props={{ pageName: 'Plan adminstration page' }} />
        },
        {
            path: '/admin/messages',
            element: <ComingSoon props={{ pageName: 'Admin Messages Page' }} />
        },
        {
            path: '/admin/integrationmanagement',
            element: <IntegrationManagementPage props={{ pageName: 'Integration management page' }} />
        },
        {
            path: '/admin/operations-dashboard',
            element: <OperationsDashboard />
        },
        {
            path: '/admin/employeemanagement',
            element: <EmployeeManagementPage props={{ pageName: 'Employeee management page' }} />
        },
        {
            path: '/admin/employeedetails',
            element: <EmployeeDetailsPage />,
            children: [{ path: ':id', element: <EmployeeDetailsPage employee /> }]
        },
        {
            path: '/admin/messagetemplates',
            element: <MessageAdminPage props={{ pageName: 'Message template management page' }} />
        },
        {
            path: '/admin/groupdetails',
            element: <GroupDetails amView />,
            children: [{ path: ':id', element: <GroupDetails amView /> }]
        },
        {
            path: '/admin/managetemplates',
            element: <ManageTemplatesPage/>,
        },
        {
            path: '/admin/pm-reports',
            element: <PMReportsPage/>,
        },
        {
            path: '/admin/worktemplate',
            element: <ModifyTemplatesPage/>,
        },
        workFlag ?
        {
            path: '/admin/works',
            element: <PracticeWorkPage/>,
        } : '',
        multyFlag || clientFlag ? {
            path: '/admin/accounts',
            element: <AccountsPage />
        } : '',
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;
