// third-party
import { FormattedMessage } from 'react-intl';
// assets
import { IconDashboard, IconMail, IconPlayCard, IconUsers, IconChartBar, IconCash, IconFriends,IconCoin } from '@tabler/icons';
import { Typography } from '@mui/material';
import { MULTY_ACCOUNT_ENABLED_FLAG, CLIENT_ACCOUNT_ENABLED_FLAG, WORK_ENABLED_FLAG } from '../config';

import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

// ===========================|| ENTRANT MENU OPTIONS ||=========================== //

const multyFlag = MULTY_ACCOUNT_ENABLED_FLAG === 'true';
const clientFlag = CLIENT_ACCOUNT_ENABLED_FLAG === 'true';
const workFlag = WORK_ENABLED_FLAG === 'true';

const AM = {
    id: 'am-menu',
    type: 'group',
    title: <FormattedMessage id="am-dashboard-title" />,
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/am',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'accounts',
            title: <FormattedMessage id="menu-accounts" />,
            type: multyFlag || clientFlag ? 'item' : 'close',
            url: '/am/accounts',
            icon: IconCoin,
            breadcrumbs: false
        },
        {
            id: 'works',
            title: <FormattedMessage id="menu-works" />,
            type: workFlag ? 'item' : 'close',
            url: '/am/works',
            icon: WorkHistoryIcon,
            breadcrumbs: false
        },
        {
            id: 'menu-reports',
            title: <FormattedMessage id="menu-reports" />,
            type: 'item',
            url: '/am/reports',
            icon: IconChartBar,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: <FormattedMessage id="clients" />,
            type: clientFlag ? 'item' : 'close',
            url: '/am/clients',
            icon: IconUsers,
            breadcrumbs: false
        },
        /*
        {
            id: 'menu-messages',
            title: <FormattedMessage id="menu-messages" />,
            type: 'item',
            url: '/am/messages',
            icon: IconMail,
            breadcrumbs: false
        },
        {
            id: 'menu-payroll',
            title: <FormattedMessage id="menu-payroll" />,
            type: 'item',
            url: '/am/payroll',
            icon: IconCash,
            breadcrumbs: false
        },
        {
            id: 'menu-team',
            title: <FormattedMessage id="menu-team" />,
            type: 'item',
            url: '/am/team',
            icon: IconFriends,
            breadcrumbs: false
        }
            */
        // {
        //     id: 'calendar',
        //     title: <FormattedMessage id="calendar" />,
        //     type: 'item',
        //     url: '/am/calendar',
        //     icon: IconCalendar,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'play-area',
        //     title: 'Play Area',
        //     type: 'item',
        //     url: '/am/play',
        //     icon: IconPlayCard,
        //     breadcrumbs: false
        // }
    ]
};

export default AM;
