/* eslint-disable */
import appLogger from '../utils/common/appLogger.ts';
import {
    apiGet,
    awsApiUpdate,
    apiUpdate,
    apiDelete,
    apiPostDjango,
    apiDjangoGet,
    deleteGroupAccount,
    apiPostFileDjango
} from './apiClient';
import endpoints from './apiConfig';

//---------------------------------------------------------------------------------------
// APIs for Groups
const createGroupAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: endpoints.groups, data });
    return response;
};
const getGroupListAPI = async () => apiDjangoGet({ url: endpoints.groups });

const getGroupAPI = async ({ id, data }) => {
    return await apiDjangoGet({ url: `${endpoints.groups}${id}/` });
};

const updateGroupAPI = async ({ id, data }) => {
    const url = `${endpoints.groups}${id}/`;
    return await apiUpdate({ url, data });
};

const updateGroupStatusAPI = async ({ id, data }) => {
    const url = `${endpoints.groups}${id}/admin-update-status/`;
    return await apiUpdate({ url, data });
};

const updateResubscribe = async ({ id, data }) => {
    const url = `${endpoints.groups}${id}/resubscribe/`;
    return await apiUpdate({ url });
};

const updateUnsubscribe = async ({ id, data }) => {
    const url = `${endpoints.groups}${id}/unsubscribe/`;
    return await apiUpdate({ url,data });
};


const updateGroupContractSigned = async (groupId, contractSigned) => {
    return await apiUpdate({ url: `${endpoints.groups}${groupId}/contract-signed/${contractSigned}/`});
};

const resetGroupAPI = async (groupId) => {
    return await apiUpdate({ url: `${endpoints.groups}${groupId}/reset-group/`});
};
const updateGroupAddressAPI = async ({ id, data }) => {
    const url = `${endpoints.groups}${id}/address/`;
    return await apiUpdate({ url, data });
};

const getGroupFamilyAPI = async ({ groupId }) => {
    const params = { groupId };
    let response = null;
    try {
        response = await apiDjangoGet({ url: endpoints.groups, params });
        return response;
    } catch (error) {
        console.error('Error in getGroupFamilyAPI', error);
        return null;
    }
};

const onboardGroupAPI = async ({ groupId, data }) => {
    const response = await apiPostDjango({ url: endpoints.groupOnboarding(groupId), data });
    return response;
};

const getAutoCompleteGroupListAPI = async (params = null) => apiDjangoGet({ url: '/groups/autoComplete-list/', params });

// ---------------------------------------------------------------------------------------
// APIs for GroupContacts
const createGroupContactAPI = async (groupId, data) => {
    return await apiPostDjango({ url: endpoints.groupContacts(groupId), data });
};
const getGroupContactsAPI = async (groupId, params=null) => {
    const newParams = params ? {...params} : {};
    return await apiDjangoGet({ url: endpoints.groupContacts(groupId), params: newParams });
};
const updateGroupContactAPI = async (groupId, id, data) => {
    return await apiUpdate({ url: `${endpoints.groupContacts(groupId)}${id}/`, data });
};

const inviteGroupContactAPI = async (groupId, id, data) => {
    console.log({ groupId, id, data });
    return await apiPostDjango({ url: `${endpoints.groups}${groupId}/groupcontacts/invite/${id}/`, data });
};
const deleteLocationContactAPI = async (groupId, id, data) => {
    return await apiDelete({ url: `${endpoints.groups}${groupId}/groupcontacts/${id}/`, data });
};

const getAutoCompleteContactListAPI = async (params = null) => apiDjangoGet({ url: '/groups/autoComplete-list-contacts/', params });

// ---------------------------------------------------------------------------------------
// APIs for LocationContacts
const createLocationContactAPI = async (groupId, data) => {
    return await apiPostDjango({ url: `/groups/${groupId}/locationcontacts`, data });
};
const getLocationContactsAPI = async (groupId) => {
    return await apiDjangoGet({ url: `/groups/${groupId}/locationcontacts` });
};
const updateLocationContactAPI = async (groupId, id, data) => {
    return await apiUpdate({ url: `/groups/${groupId}/locationcontacts/${id}`, data });
};

// ---------------------------------------------------------------------------------------
// APIs for Bank and Credit Details
const getGroupAccountData = async (groupId) => {
    return await apiDjangoGet({ url: `/groups/${groupId}/accounts` });
};

const updateBankAndCreditDetails = async (id, data) => {
    return await apiPostDjango({ url: `/groups/${id}/accounts`, data });
};

const updateAccountDetails = async (id, data) => {
    return await apiUpdate({ url: `/groups/${id}/accounts`, data });
};

const deleteAccountDetails = async (id, data) => {
    return await deleteGroupAccount({ url: `/groups/${id}/accounts`, data });
};

const getGroupAccountList = async (data, params = {}) => {
    return await apiPostDjango({ url: `/accounts`, data, params: params });
};

// Onboarding APIs
const updateOnboardingLink = async (data) => {
    console.log(data);
    return apiPostDjango({ url: 'group-customer-agreements/', data });
};

const updateonboardingStatus = async (groupId) => {
    const url = `group-customer-agreements-update/${groupId}/`;
    return await apiUpdate({ url });
};

const updateOnboardingCreditCardStatus = async (id, data) => {
    const url = `group-crediCard-account-updates/${id}/`;
    return await apiPostDjango({ url: url, data: data });
};

const contractSignedWorkflow = async (id, data) => {
    return await apiPostDjango({ url: `/group/${id}/workflow/contract-signed/`, data });
};
const paymentMadeWorkflow = async (id, data) => {
    return await apiPostDjango({ url: `/group/${id}/workflow/payment-made/`, data });
};

// Upload and delete group agreement in s3
const uploadFile = async (fileType, id, data) => {
    return await apiPostFileDjango({ url: `upload/groups/${id}/file/${fileType}/`, data });
};

const deleteFile = async (data) => {
    return await apiDelete({ url: `upload/groups/${data.id}/file/${data.type}/${data.name}` });
};



// API's for ADD , UPDATE , GET , DELETE for a Group
export const addClientContract = async (id, data) => {
    return await apiPostFileDjango({ url: `groups/${id}/client-contract/`, data });
};

export const getContractList = async (group_Id) => {
    return await apiDjangoGet({ url: `groups/${group_Id}/contract-list/` });
};

export const updateContract = async (groupId, id, data) => {
    return await apiUpdate({ url: `groups/${groupId}/contract-list/${id}/`, data });
};

export const deleteContract = async (groupId, id) => {
    return await apiDelete({ url: `groups/${groupId}/contract-list/${id}/` });
};


// Billing
const getGroupBillingDetails = async (id) => {
    return await apiDjangoGet({ url: `/groups/${id}/billing/` });
};

// Report Settins
const getGroupReportSettingsAPI = async (id) => {
    return await apiDjangoGet({ url: `/groups/${id}/settings/report/` });
};
const updateGroupReportSettingsAPI = async ({ id, data }) => {
    const url = `/groups/${id}/settings/report/`;
    return await apiUpdate({ url, data });
};

// Onboarding status
const getGroupOnboardingStatusAPI = async (id) => {
    return await apiDjangoGet({ url: `/groups/${id}/onboarding/status/` });
};
const updateGroupOnboardingStatusAPI = async ({ id, data }) => {
    const url = `/groups/${id}/onboarding/status/`;
    return await apiUpdate({ url, data });
};

// migration client to group APIs

const upgradeClientToGroup = async ({ groupId, clientId }) => {
    return await apiPostDjango({ url: `/group/${groupId}/upgrade/${clientId}/`, data: {} });
};

const upgradeClientToGroupClientAgreement = async ({ groupId }) => {
    return await apiPostDjango({ url: `/migrate/client-agreement/${groupId}/`, data: {} });
};

const upgradeClientToGroupReports = async ({ groupId }) => {
    return await apiPostDjango({ url: `/migrate/reports/${groupId}/`, data: {} });
};

const upgradeClientToGroupCreditCard = async ({ groupId }) => {
    return await apiPostDjango({ url: `/migrate/credit-card-accounts/${groupId}/`, data: {} });
};

const upgradeClientToGroupBankAccount = async ({ groupId }) => {
    return await apiPostDjango({ url: `/migrate/bank-accounts/${groupId}/`, data: {} });
};

const upgradeClientToGroupComplete = async ({ groupId }) => {
    return await apiPostDjango({ url: `/migrate/complete/${groupId}/`, data: {} });
};

const updateConectionStatus = async (data) => {
    return await apiPostDjango({ url: '/administration/update-connection-status/', data });
};
const getOnboardingPending = async (data, params) => {
    return await apiPostDjango({ 
        url: 'analytics/operations/onboarding/pending/', 
        data,  // Sent in the request body
        params // Sent as query parameters
    });
};

const getReportsPending = async (data, params) => {
    return await apiPostDjango({ 
        url: 'analytics/operations/reports/pending/', 
        data,  // Sent in the request body
        params // Sent as query parameters
    });
};

const getEventdetails = async (data, params) => {
    return await apiDjangoGet({ 
        url: 'analytics/events/details', 
        params
    });
};

// ---------------------------------------------------------------------------------------
export {
    createGroupAPI,
    getGroupListAPI,
    getGroupAPI,
    updateGroupAPI,
    updateGroupStatusAPI,
    updateGroupContractSigned,
    resetGroupAPI,
    updateGroupAddressAPI,
    createGroupContactAPI,
    getGroupContactsAPI,
    updateGroupContactAPI,
    inviteGroupContactAPI,
    deleteLocationContactAPI,
    getAutoCompleteContactListAPI,
    createLocationContactAPI,
    getLocationContactsAPI,
    updateLocationContactAPI,
    getGroupFamilyAPI,
    onboardGroupAPI,
    getAutoCompleteGroupListAPI,
    getGroupAccountData,
    updateBankAndCreditDetails,
    updateAccountDetails,
    deleteAccountDetails,
    getGroupAccountList,
    updateonboardingStatus,
    updateOnboardingLink,
    updateOnboardingCreditCardStatus,
    contractSignedWorkflow,
    paymentMadeWorkflow,
    uploadFile,
    deleteFile,
    getGroupBillingDetails,
    getGroupReportSettingsAPI,
    updateGroupReportSettingsAPI,
    getGroupOnboardingStatusAPI,
    updateGroupOnboardingStatusAPI,
    // migration client to group APIs
    upgradeClientToGroup,
    upgradeClientToGroupClientAgreement,
    upgradeClientToGroupReports,
    upgradeClientToGroupCreditCard,
    upgradeClientToGroupBankAccount,
    upgradeClientToGroupComplete,
    // end of migration
    updateConectionStatus,
    updateResubscribe,
    updateUnsubscribe,
    getOnboardingPending,
    getReportsPending,
    getEventdetails
};
