// routing
import Routes from 'routes';
import ReactGA from 'react-ga4';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import AppStatus from 'ui-component/extended/AppStatus';
import ThemeCustomization from 'themes';

// auth provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import i18n from './i18n';

// ==============================|| APP ||============================== //
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
const poolId = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;
const clientId = process.env.REACT_APP_AWS_APP_CLIENT_ID;
const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <>
                            <Routes />
                            {/* <h1>poolId: {poolId}</h1>
                            <h1>clientId: {clientId}</h1> */}
                            <AppStatus />
                        </>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
