import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getReportAccess, { updateReportAccess } from "../../APIs/reportAccess";

const GET_REPORT_ACCESS = createAsyncThunk(
  "get-report-access",
  async ({ data, id, reportType }, { rejectWithValue }) => {
    try {
      const response = await getReportAccess(data, id, reportType);
      return { data: response.data, requestedId: id }; // Include requestedId separately
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const updateReportAccessResponse = (data) => data.map(item => ({
  ...item,
  report_type: item.report_type.replace(/_/g, '-')
}));

const UPDATE_REPORT_ACCESS = createAsyncThunk(
  "update-report-access",
  async ({ data, id, reportType }, { rejectWithValue }) => {
    try {
      const response = await updateReportAccess(data, id, reportType );
      return { data: response.data, requestedId: id }; // Include requestedId separately
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


const initialState = {
  reportAccessInitialized: false,
  reportAccessData: [],
  currentReportAccessId: null, // Store the latest accessed ID
  isLoading: false,
  error: null,
};

const reportAccessSlice = createSlice({
  name: "reportAccess",
  initialState,
  reducers: {
    logout(state) {
      state.reportAccessInitialized = false;
      state.isLoading = false;
      state.reportAccessData = [];
      state.currentReportAccessId = null;
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setReportInitialized(state) {
      state.reportAccessInitialized = !state.reportAccessInitialized;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_REPORT_ACCESS.fulfilled, (state, action) => {
        const newData = action.payload.data || []; 
        const responseId = newData.length ? newData[0]?.group : null;
        const requestedId = action.payload.requestedId; 

        const newId = responseId || requestedId;

        if (!newId) return; 
        state.currentReportAccessId = newId;

        if (!Array.isArray(state.reportAccessData)) {
          state.reportAccessData = [];
        }


        const existingIndex = state.reportAccessData.findIndex(rep => rep.id === newId);

        if (existingIndex !== -1) {
          state.reportAccessData[existingIndex] = { id: newId, data: [...updateReportAccessResponse(newData)] };
        } else {
          state.reportAccessData.push({ id: newId, data: [...updateReportAccessResponse(newData)] });
        }

        // state.reportAccessInitialized = true;
      })
      .addCase(UPDATE_REPORT_ACCESS.fulfilled, (state, action) => {
        const updatedData = action.payload.data || [];
        const responseId = updatedData.length ? updatedData[0]?.group : null;
        const requestedId = action.meta.arg.id; 

        const updatedId = responseId || requestedId;

        if (!updatedId) return; 

        state.currentReportAccessId = updatedId;

        if (!Array.isArray(state.reportAccessData)) {
          state.reportAccessData = [];
        }


        state.reportAccessData = state.reportAccessData.map(rep =>
          rep.id === updatedId ? { id: updatedId, data: [...updateReportAccessResponse(updatedData)] } : rep
        );

        // state.reportAccessInitialized = true;
      });
  },
});

const { logout, setIsLoading ,setReportInitialized} = reportAccessSlice.actions;

export { GET_REPORT_ACCESS, UPDATE_REPORT_ACCESS, logout, setIsLoading,setReportInitialized };
export default reportAccessSlice.reducer;
