/* eslint-disable */

import { apiGet, awsApiUpdate, apiDelete, apiDjangoGet, apiPostDjangoWithResponse, apiPostWithResponse, apiUpdateWithReponse, apiPostDjango, apiUpdate } from './apiClient';

const getReportAccess=async(data,id,reportType)=>{
  console.log(data,id,reportType); 
  const response=await apiDjangoGet({url:`administration/personalization/${id}/report/${reportType}/`,data});
  return response;
}

const updateReportAccess=async(data,id,reportType)=>{
  console.log("ALL",{data,id,reportType})
  const response=await apiUpdate({url:`administration/personalization/${id}/report/${reportType}/`,data});
  return response;
}
const postReportAccess=async(data,id,reportType)=>{
  console.log("ALL",{data,id,reportType})
  const response=await apiPostDjango({url:`administration/personalization/${id}/report/${reportType}/`,data});
  return response;
}
const reportAccessList=async(data)=>{
  console.log("ALL",{data})
  const response=await apiPostDjango({url:`administration/access-list/`,data});
  return response;
}

export {
  updateReportAccess,
  postReportAccess,
  reportAccessList
}
export default getReportAccess;